import { withExtTenantIdRequest } from '@/utils/request';

// 获取我收藏的商品分页列表
export function fetchListMineForMerchant(data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodityFavorites/listMineForMerchant',
    method: 'post',
    data
  });
}

// 取消收藏
export function cancelFavorites(commodityId) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/commodityFavorites/cancel?commodityId=${commodityId}`,
    method: 'post'
  });
}

// 商品收藏
export function createFavorites(commodityId) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/commodityFavorites/create?commodityId=${commodityId}`,
    method: 'post'
  });
}

// 根据商品id返回用户收藏的商品ID
export function getListFavoriteCommodityByIds(data) {
  return withExtTenantIdRequest({
    url: `/commodity/api/commodityFavorites/listFavoriteCommodityByIds`,
    method: 'post',
    data
  });
}

export function cancelByIds(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/commodityFavorites/cancelByIds`,
    method: 'post',
    data
  });
}
