<template>
  <div class="goods-collect">
    <div class="form">
      <p class="form__label">商品名称：</p>
      <el-input v-model="commodityName" placeholder="请输入商品名称"></el-input>
      <el-button type="primary" @click="onSearch">搜索</el-button>
      <el-button class="form__red form__red--box" @click="onReset">重置</el-button>
      <el-button class="form__red" @click="delGoods" type="text">清除所有失效商品</el-button>
    </div>
    <div class="list" v-if="list.length">
      <router-link
        class="list__item"
        v-for="i in list"
        :key="i.id"
        :to="`/syoung/commodity/goods-detail/${i.id}`"
      >
        <div class="list__item--imgBox">
          <oss-image :src="i.thumbnailUrl" class="list__item--img" />
          <div class="list__item--invalid" v-if="i.status !== '1' || i.isHidden === '1'">
            已失效
          </div>
        </div>
        <p class="list__item--name commo-ellipsis-2">{{ i.name }}</p>
        <p class="list__item--price">
          批发价：<span :class="i.status === '1' && i.isHidden === '0' ? 'price' : 'priceInvalid'"
            >￥{{ i.price }}</span
          >
        </p>
      </router-link>
    </div>
    <div class="no__list" v-else>暂无数据</div>
  </div>
</template>

<script>
import { fetchListMineForMerchant, cancelByIds } from '@/api/commodity/goods-collect';
export default {
  name: 'goods-collect',
  data() {
    return {
      commodityName: '',
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const listQuery = this.commodityName ? { commodityName: this.commodityName } : {};
      fetchListMineForMerchant(listQuery).then(res => {
        this.list = res.data;
      });
    },
    onSearch() {
      this.getList();
    },
    onReset() {
      this.commodityName = '';
      this.getList();
    },
    delGoods() {
      this.$confirm('此操作将清除所有失效商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const ids = [];
          this.list.forEach(i => {
            if (i.status !== '1' || i.isHidden === '1') {
              ids.push(i.id);
            }
          });
          if (ids.length < 1) {
            this.$message({
              type: 'warning',
              message: '无失效商品',
            });
          } else {
            cancelByIds(ids).then(response => {
              this.getList();
              this.$message({
                type: 'success',
                message: '已清空',
              });
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.goods-collect {
  width: 1180px;
  color: #222;
}
.form {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .el-input {
    width: 272px;
    margin-right: 20px;
  }
  .el-button {
    margin: 0;
    margin-right: 20px;
  }
  &__red {
    color: #ab0033;
    &--box {
      border-color: #ab0033;
    }
  }
}
.list {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 100px;
  &__item {
    width: 228px;
    height: 300px;
    box-shadow: 0px 0px 8px 0px rgba(60, 60, 60, 0.3);
    padding: 10px;
    margin-right: 9px;
    margin-bottom: 10px;
    &:nth-of-type(5n) {
      margin-right: 0;
    }
    &--imgBox {
      position: relative;
      margin-bottom: 10px;
    }
    &--img {
      width: 208px;
      height: 208px;
      display: block;
    }
    &--invalid {
      width: 208px;
      height: 26px;
      background: rgba(0, 0, 0, 0.5);
      text-align: center;
      line-height: 26px;
      color: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &--name {
      color: #333;
      height: 34px;
      line-height: 17px;
    }
    &--price {
      color: #666;
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.price {
  color: #ab0033;
}
.priceInvalid {
  color: #666;
}

.no__list {
  text-align: center;
  color: #909399;
  height: 280px;
  line-height: 280px;
}
</style>
